<template>
    <div>
        <b-row>
            <b-col cols="2">
                <picker-of-dates type="year" format="yyyy" :clearable="false" v-model="selectedYear">
                    <template v-slot:prepend>
                        <b-input-group-prepend>
                            <b-button size="sm" variant="info" @click="prevYear">
                                <font-awesome-icon icon="arrow-left"/>
                            </b-button>
                        </b-input-group-prepend>
                    </template>
                    <template v-slot:append>
                        <b-input-group-append>
                            <b-button size="sm" variant="info" @click="nextYear">
                                <font-awesome-icon icon="arrow-right" />
                            </b-button>
                        </b-input-group-append>
                    </template>
                </picker-of-dates>
            </b-col>
            <b-col cols="10" class="text-right">
                <b-button size="sm" variant="dark" @click="showNewTrackModal">Add New</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <br />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                        small
                        striped
                        hover
                        stacked="sm"
                        head-variant="dark"
                        :fields="tableFields"
                        :items="tracks">
                    <template
                            v-slot:cell(track)="row">
                        <strong>{{row.item.title}}</strong>
                    </template>
                    <template
                            v-slot:cell(action)="row">
                        <b-link
                                @click="row.toggleDetails" class="float-right">
                            {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                        </b-link>
                    </template>
                    <template
                            v-slot:row-details="row">
                        <TrackDetails
                                @done="row.toggleDetails"
                                @cancel="cancelTrack"
                                @delete="deleteTrack"
                                :show-buttons="true"
                                :track="row.item" />
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <!--NEW TRACK MODAL-->
        <b-modal
                scrollable
                :id="newTrackModalId"
                size="xl"
                title="Add New Track"
                header-bg-variant="dark"
                header-text-variant="white"
                @hidden="cancelNewTrack">
            <TrackDetails
                    :show-buttons="false"
                    :track="newTrack" />
            <!-- Use custom buttons for new track creation -->
            <template
                    v-slot:modal-footer="{ hide }">
                <b-button-group
                        size="sm">
                    <b-button
                            @click="cancelNewTrack"
                            variant="primary">Cancel</b-button>
                    <b-button
                            @click="saveNewTrack"
                            variant="success">Save</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import {NULL_TRACK} from '@/model/track';
import _ from 'underscore';
import {subYears, addYears} from 'date-fns';
import PickerOfDates from '@/components/shared/PickerOfDates';
import {sprintf} from "sprintf-js";
import TrackDetails from "@/views/private/tracks/TrackDetails";
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import User from "../../../model/user";

function shouldIBeHere(activeUser) {
    //Only admins should have access to Tracks
    const allowed = !!activeUser && activeUser instanceof User ? activeUser.isAnAdministrator() : false;
    return allowed;
}

async function routeHandler(to, from, next) {
    const currentUser = store.getters['userSession/getUser'];
    // console.log(currentUser);
    if (!currentUser) {
        //Do not route for falsy current user
        return next(false);
    }
    else {
        //Route home for disallowed users
        return shouldIBeHere(currentUser) ? next() : next("/admin");
    }
}


@Component({
    components: {
        TrackDetails,
        PickerOfDates
    }
})


export default class Tracks extends Vue {
    selectedYear = new Date();
    newTrack = NULL_TRACK;

    prevYear() {
        this.selectedYear = subYears(this.selectedYear, 1);
    }

    nextYear() {
        this.selectedYear = addYears(this.selectedYear, 1);
    }

    cancelNewTrack() {
        this.newTrack.rollback();
        this.newTrack.disableEditMode();
        this.$bvModal.hide(this.newTrackModalId);
    }

    async saveNewTrack() {
        //Per CITF request, alert rather than disabling
        if (this.newTrack.disableSave) {
            this.$bvModal.msgBoxOk('Please address the highlighted errors before saving.', {
                title: 'Error',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            return;
        }
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to save this new track?', {
                title: 'Confirm Save New Track',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (check) {
            try {
                await store.dispatch('tracks/saveTrack', this.newTrack, {root: true});
                this.newTrack.rollback();
                this.newTrack.disableEditMode();
                this.$bvModal.hide('new-track-modal');
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
    }

    get newTrackModalId() {
        return 'new-track-modal';
    }

    showNewTrackModal() {
        this.setNewTrack();
        this.$bvModal.show(this.newTrackModalId);
    }

    setNewTrack() {
        const template = store.getters['tracks/getTemplateTrack'];
        this.newTrack = template;
    }

    async deleteTrack(track) {
        const deleteCheck = await this.$bvModal.msgBoxConfirm(sprintf(
            'Are you sure you wish to delete the the track starting %s?', track.title), {
            title: 'Confirm Delete',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            headerBgVariant: 'dark',
            headerTextVariant: 'white'
        });
        if (deleteCheck) {
            try {
                await store.dispatch('tracks/deleteTrack', track.workspace, {root: true});
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
    }

    async cancelTrack(track) {
        const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
            'Are you sure you wish to cancel the the track starting %s?', track.title), {
            title: 'Confirm Cancel',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            headerBgVariant: 'dark',
            headerTextVariant: 'white'
        });
        if (cancelCheck) {
            track.workspace.canceled = true;
            try {
                await store.dispatch('tracks/cancelTrack', track.workspace, {root: true});
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                track.workspace.canceled = false;
            }
        }
    }

    get tracks() {
        const tracks = store.getters['tracks/getTracks'];
        const filteredTracks = _.chain(tracks)
            .filter(track => {
                //Don't show canceled tracks
                return !track.canceled;
            })
            .filter(track => {
                return track.hasSessionInYear(this.selectedYear.getFullYear());
            })
            .sortBy(track => {
                return track['program1'].dates[0] || new Date();
            })
            .value();
        //Sort descending
        return filteredTracks.reverse();
    }

    get tableFields() {
        return [{
            key: 'track',
            label: 'Track',
            sortable: true
        }, {
            key: 'action',
            label: '',
            sortable: false
        }];
    }

    async mounted() {
        _.each([
            'registration/loadRegistrableSessionIds',
            'registration/loadRegistrableUserIds',
            'registration/loadCancelableUserIds'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        try {
            await store.dispatch('tracks/loadTracks');
            await store.dispatch('tracks/loadTemplateTrack');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async beforeRouteEnter(to, from, next) {
        store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create('Tracks', {name: 'sessions'}, true)
        ]);
        try {

            await store.dispatch('tracks/loadTemplateTrack');
        }
        catch (error) {
            const vm = new Vue();
            vm.$bvToast.toast(error.message, errorToastOptions);
        }
        //Load before routing so user can't even try to create new track without template
        routeHandler(to, from, next);
    }

    beforeRouteUpdate(to, from, next) {
        routeHandler(to, from, next);
    }
}
</script>

<style scoped>
</style>
