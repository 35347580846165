<template>
    <div>
        <b-row>
            <b-col cols="6">
                <ul>
                    <li v-for="idx in [1,2,3,4]" class="session">
                        <span :class="{ 'dirty-session': track.workspace['program'+idx].dirty && !track.workspace.isNew }"
                              v-html="programText(idx, false)" />
                        <br />
                        (
                        <b-link @click="edit(idx, false)">edit</b-link>
                        <template v-if="!track.isNew && track['program'+idx].isSet">
                            |
                            <b-link @click="showDetails(idx, false)">details</b-link>
                            <template v-if="registrableSessionIds.includes(track['program'+idx].sessionId)">
                                |
                                <b-link @click="register(idx, false)">register</b-link>
                            </template>
                            <template>
                                |
                                <b-link @click="roster(idx, false)">roster</b-link>
                            </template>
                        </template>
                        )
                        <span v-if="track.workspace['program'+idx].hasErrors" class="review-required">REVIEW</span>
                    </li>
                    <li v-for="idx in [1,3]" class="session">
                        <span :class="{ 'dirty-session': track.workspace['mentorProgram'+idx].dirty && !track.workspace.isNew }"
                              v-html="programText(idx, true)" />
                        <br />
                        (
                        <b-link @click="edit(idx, true)">edit</b-link>
                        <template v-if="!track.isNew && track['program'+idx].isSet">
                            |
                            <b-link @click="showDetails(idx, true)">details</b-link>
                            <template v-if="registrableSessionIds.includes(track['mentorProgram'+idx].sessionId)">
                                |
                                <b-link @click="register(idx, true)">register</b-link>
                            </template>
                            <template>
                                |
                                <b-link @click="roster(idx, true)">roster</b-link>
                            </template>
                        </template>
                        )
                        <span v-if="track.workspace['mentorProgram'+idx].hasErrors" class="review-required">REVIEW</span>
                    </li>
                </ul>
            </b-col>
            <b-col cols="6" v-if="track.editMode">
                <template>
                    <span v-if="track.workspace.editProgram.canceled"
                          class="warning">WARNING: This session has been canceled.<br /><br />
                    </span>
                    <span v-if="!track.workspace.editProgram.registrationsAccepted"
                          class="note">Registrations are not currently accepted.<br /><br />
                    </span>
                </template>
                <b-tabs content-class="mt-3"
                        v-model="tabIndex"
                        @activate-tab="changeTab">
                    <b-tab :title="editLabel(track.workspace.editProgram, 'Details')">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Start Date → End Date"
                                        :state="errorState('dates')"
                                        invalid-feedback="Required">
                                    <ProgramDateRange
                                            @input="warnForDateChanges"
                                            v-model="track.workspace.editProgram.programDates"
                                            :state="errorState('dates')"
                                            :disabled="!!track.workspace.editProgram.registrations" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Length (days)">
                                    <b-input size="sm"
                                             v-model="track.workspace.editProgram.programDays"
                                             disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Min Seats"
                                        :state="errorState('minSeats')"
                                        invalid-feedback="Required">
                                    <b-input type="number" size="sm"
                                             v-model="track.workspace.editProgram.programMinSeats"
                                             :state="errorState('minSeats')" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Max Seats"
                                        :state="errorState('maxSeats')"
                                        invalid-feedback="Required">
                                    <b-input type="number" size="sm"
                                             v-model="track.workspace.editProgram.programMaxSeats"
                                             :state="errorState('maxSeats')" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Instructors Required"
                                        :state="errorState('instructorsNeeded')"
                                        invalid-feedback="Required">
                                    <b-input type="number" size="sm"
                                             v-model="track.workspace.editProgram.programInstructorsNeeded"
                                             :disabled="!track.workspace.editProgram.isNew"
                                             :state="errorState('instructorsNeeded')" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Open Seats">
                                    <b-input size="sm"
                                             v-model="track.workspace.editProgram.availableSeats"
                                             disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group
                                        label="Allow Registrations">
                                    <b-form-checkbox
                                            switch
                                            :value="true"
                                            :unchecked-value="false"
                                            v-model="track.workspace.editProgram.programAllowRegistrations" />
                                </b-form-group>
                                <b-form-group
                                        label="Admin Registrations Only">
                                    <b-form-checkbox
                                            switch
                                            :value="true"
                                            :unchecked-value="false"
                                            v-model="track.workspace.editProgram.programAdminRegistration" />
                                </b-form-group>
                                <b-form-group
                                        label="Auto Registration Closure">
                                    <b-form-checkbox
                                            switch
                                            :value="true"
                                            :unchecked-value="false"
                                            v-model="track.workspace.editProgram.programAutoClose" />
                                </b-form-group>
                                <b-form-group
                                        label="COVID-19 Registration Restriction">
                                    <b-form-checkbox
                                            switch
                                            :value="true"
                                            :unchecked-value="false"
                                            v-model="track.workspace.editProgram.programRequireVaccine" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                        label="Instructor / Developer Comments">
                                    <b-textarea
                                            size="sm" rows="7" max-rows="7"
                                            v-model="track.workspace.editProgram.programComment" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button
                                        v-if="!disableCancelRegistrations"
                                        variant="dark"
                                        size="sm"
                                        @click="cancelRegistrations(track.workspace.editProgram)">Cancel All Session Registrations</b-button>
                                <br/><br/>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="editLabel(track.workspace.editProgram, 'Setup')">
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Start Time First Day → End Time Last Day">
                                    <ProgramTimeRange
                                            v-model="track.workspace.editProgram.programTimes" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Equipment/Props">
                                    <b-textarea
                                            size="sm"
                                            v-model="track.workspace.editProgram.programEquipment" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Classroom/Shop Space">
                                    <b-textarea
                                            size="sm"
                                            v-model="track.workspace.editProgram.programClassroom" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Room Setup">
                                    <b-textarea
                                            size="sm"
                                            v-model="track.workspace.editProgram.programRoomSetup" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Audio/Visual">
                                    <b-textarea
                                            size="sm"
                                            v-model="track.workspace.editProgram.programAudioVisual" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Food/Beverage">
                                    <b-textarea
                                            size="sm"
                                            v-model="track.workspace.editProgram.programFoodBeverage" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Additional Setup">
                                    <b-textarea
                                            size="sm"
                                            v-model="track.workspace.editProgram.programAdditionalSetup" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        label="Need Binders">
                                    <b-form-checkbox
                                            switch
                                            :value="true"
                                            :unchecked-value="false"
                                            v-model="track.workspace.editProgram.programBinders" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="editLabel(track.workspace.editProgram, 'Allocations')"
                           v-if="allowAllocations && !track.workspace.isNew"
                           lazy>
                        <Allocations :workshop-instance="track.workspace.editProgram" />
                        <br /><br /><br />
                    </b-tab>
                </b-tabs>
                <b-button-group v-if="showButtons && !hideButtons">
                    <b-button
                            variant="primary"
                            size="sm"
                            @click="cancel">Cancel Edit</b-button>
                    <b-button
                            v-if="!track.disableDelete"
                            variant="danger"
                            size="sm"
                            @click="$emit('delete', track)">Delete Track</b-button>
                    <b-button
                            v-if="!track.disableCancel"
                            variant="warning"
                            size="sm"
                            @click="$emit('cancel', track)">Cancel Track</b-button>
                    <b-button
                            :disabled="this.track.disableSave"
                            variant="success"
                            size="sm"
                            @click="save">Save Track</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 ok-variant="primary"
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!-- REGISTRATION MODAL -->
        <b-modal :id="sessionRegistrationModalId"
                 size="xl"
                 scrollable
                 ok-only
                 ok-variant="primary"
                 :title="registrationTitle"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionRegistration
                    :allow-registration-changes="true"
                    :session="session" />
        </b-modal>
        <!-- ROSTER MODAL -->
        <b-modal :id="sessionRosterModalId"
                 size="xl"
                 scrollable
                 ok-only
                 ok-variant="primary"
                 :title="rosterTitle"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionRoster
                    :allow-registration-changes="true"
                    :session="session" />
        </b-modal>
    </div>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import store from '@/store/store';
    import {NULL_TRACK} from '@/model/track';
    import _ from 'underscore';
    import formatters from '@/util/formatters';
    import {toDate, addDays} from 'date-fns';
    import ProgramDateRange from '@/views/private/tracks/ProgramDateRange.vue';
    import {NULL_SESSION} from "@/model/session";
    import {sprintf} from "sprintf-js";
    import SessionRegistration from '@/views/private/tracks/SessionRegistration.vue';
    import SessionRoster from '@/views/private/tracks/SessionRoster.vue';
    import {Track} from "../../../model/track";
    import SessionDetails from "./SessionDetails";
    import {Session} from "../../../model/session";
    import ProgramTimeRange from "./ProgramTimeRange";
    import registrationDao from "../../../dao/registration_dao";
    import trackDao from "../../../dao/track_dao";
    import {errorModalOptions} from "../../../util/formatters";
    import Allocations from "./Allocations";

@Component({
    components: {
        Allocations,
        ProgramTimeRange,
        ProgramDateRange,
        SessionRoster,
        SessionRegistration,
        SessionDetails
    },
    computed: {
        disableCancelRegistrations: function () {
            // console.log(this.track);
            if (!this.track.workspace.editProgram) {
                return true;
            } else {
                return this.track.workspace.editProgram.disableCancelRegistrations;
            }
        }
    }
})

export default class TrackDetails extends Vue {
    @Prop({type: Boolean, default: true}) showButtons;
    @Prop({type: Track, default: NULL_TRACK}) track;
    tabIndex = 0;
    hideButtons = false;
    //Use a class property to hold pointer to session under review (as distinct from track's editProgram)
    session = NULL_SESSION;
    allowAllocations = true;

    changeTab(newTabIndex) {
        //Hide buttons for allocations tab only
        this.hideButtons = newTabIndex === 2;
    }

    async mounted() {
        // console.log(this.track);
    }

    edit(idx, mentorSession) {
        const editProgram = this.getWorkspaceSession(idx, mentorSession);
        this.track.workspace.editProgram = editProgram;
    }

    errorState(field) {
        return !this.track.workspace.editProgram.sessionErrors[field];
    }

    editLabel(session, type) {
        return sprintf('%sProgram %d%s',
            session.mentorSession ? 'Mentor ' : '',
            session.idx,
            _.isEmpty(type) ? '' : ' ' + type
        );
    }

    register(idx, mentorSession) {
        this.setSession(idx, mentorSession);
        setTimeout(() => {
            this.$bvModal.show(this.sessionRegistrationModalId);
        }, 500);
    }

    roster(idx, mentorSession) {
        this.setSession(idx, mentorSession);
        setTimeout(() => {
            this.$bvModal.show(this.sessionRosterModalId);
        }, 500);
    }

    cancel() {
        this.track.rollback();
        this.track.disableEditMode();
        this.$emit('done');  //Inform parent to hide details
    }

    async cancelRegistrations(session) {
        // console.log(session);
        const cancelCheck = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to cancel all registrations for this session?', {
            title: 'Confirm Cancel All Session Registrations',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            headerBgVariant: 'dark',
            headerTextVariant: 'white'
        });
        if (cancelCheck) {
            try {
                await registrationDao.cancelAllRegistrations(session.sessionId);
                const [alertResult, newTrack] = await Promise.allSettled([
                    this.$bvModal.msgBoxOk(
                        'All registrations for this session have been canceled.', {
                            title: 'Success',
                            noCloseOnBackdrop: true,
                            noCloseOnEsc: true,
                            headerBgVariant: 'dark',
                            headerTextVariant: 'white'
                        }),
                    trackDao.getTrackById(this.track.id)
                ]);
                //Update track in place
                if (newTrack.value instanceof Track && !_.isEqual(newTrack, NULL_TRACK)) {
                    _.forEach(Track.programKeys(), key => {
                        this.track[key] = newTrack.value[key];
                        this.track.workspace[key] = newTrack.value[key];
                    });
                    //Update edit program for reactivity
                    const editProgramKey = sprintf('%srogram%d',
                        this.track.workspace.editProgram.mentorSession ? 'mentorP' : 'p',
                        this.track.workspace.editProgram.idx);
                    this.track.workspace.editProgram = newTrack.value[editProgramKey];
                }
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
    }

    async save() {
        const saveCheck = await this.$bvModal.msgBoxConfirm(sprintf(
            'Are you sure you wish to save the the track starting %s?', this.track.title), {
            title: 'Confirm Save',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            headerBgVariant: 'dark',
            headerTextVariant: 'white'
        });
        if (saveCheck) {
            try {
                await store.dispatch('tracks/saveTrack', this.track, {root: true});  //Store will save workspace, commit
                this.$emit('done');  //Inform parent to hide details
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
    }

    @Watch('track.workspace.editProgram.dates')
    populateMentorSessionFromTraineeSession() {
        const session = this.track.workspace.editProgram;
        if (_.isNull(session) || _.isUndefined(session) || session.mentorSession ||
            _.contains([2, 4], session.idx) || !session.isSet) {
            return;
        }
        const mentorSession = this.track.workspace['mentorProgram' + session.idx];
        if (mentorSession.isSet) {
            return;
        }
        const dates = session.dates || [];
        if (2 > dates.length) {
            return;
        }
        mentorSession.programDates = [
            addDays(toDate(dates[0]), 1),
            toDate(dates[1])
        ];
    }

    get registrableSessionIds() {
        //Loader called from parent component (Tracks)
        const sessionIds = this.$store.getters['registration/registrableSessionIds'];
        return sessionIds;
    }

    get sessionDetailsModalId() {
        return sprintf('session-details-modal-%s', Session.isValidSession(this.session) ? this.session.sessionId : 'invalid');
    }

    get sessionRegistrationModalId() {
        return sprintf('session-registration-modal-%s', Session.isValidSession(this.session) ? this.session.sessionId : 'invalid');
    }

    get registrationTitle() {
        return sprintf('Registration: %s', Session.isValidSession(this.session) ? this.session.label : 'Invalid Session');
    }

    get sessionRosterModalId() {
        return sprintf('session-roster-modal-%s', Session.isValidSession(this.session) ? this.session.sessionId : 'invalid');
    }

    get rosterTitle() {
        return sprintf('Roster: %s', Session.isValidSession(this.session) ? this.session.label : 'Invalid Session');
    }

    setSession(idx, mentorSession) {
        this.session = this.getWorkspaceSession(idx, mentorSession);
    }

    showDetails(idx, mentorSession) {
        this.setSession(idx, mentorSession);
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    sessionKey(idx, mentorSession) {
        return sprintf('%srogram%d', mentorSession === true ? 'mentorP' : 'p', idx);
    }

    getSession(idx, mentorSession) {
        const key = this.sessionKey(idx, mentorSession);
        return this.track[key];
    }

    getWorkspaceSession(idx, mentorSession) {
        const key = this.sessionKey(idx, mentorSession);
        return this.track.workspace[key];
    }

    async warnForDateChanges() {
        if (!this.track.isNew) {
            await this.$bvModal.msgBoxOk('Caution: program session date updated! ' +
                'Please ensure all other program session dates are adjusted accordingly.',
                {
                    title: 'Check Dates',
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
        }
    }

    programText(idx, mentorSession) {
        const p = this.getSession(idx, mentorSession);
        const wsp = this.getWorkspaceSession(idx, mentorSession);
        if (!p.isSet && !wsp.dirty) {
            return sprintf('%sProgram %d is not set.', mentorSession ? 'Mentor ' : '', idx);
        }
        const isDirty = wsp.dirty;
        const dates = isDirty ? wsp.dates : p.dates;
        const s = dates[0];
        const e = dates[1];
        return sprintf('%sProgram %d: %s &rarr; %s<br />%s',
            mentorSession ? 'Mentor ' : '',
            idx,
            formatters.date(s, 'M/d/yyyy'),
            formatters.date(e, 'M/d/yyyy'),
            wsp.availableSeatsOnlyLabel
        );
    }
}
</script>

<style scoped>
    span.dirty-session {
        color: #F00;
    }
    span.warning {
        color: red;
        font-weight: bold;
    }
    span.note {
        color: royalblue;
    }
    span.review-required {
        color: orangered;
        font-size: x-small;
        font-variant: all-small-caps;
    }
    li.session {
        margin-left: -25px;
        padding-left: 2px;
    }
</style>
