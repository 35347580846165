<template>
    <div>
        <b-row>
            <b-col>
                <p>{{confirmationMessage}}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="float-right">
                    <b-button
                            variant="warning"
                            @click="cancel">Cancel</b-button>
                    <b-button
                            variant="primary"
                            @click="register">Register</b-button>
                </div>
            </b-col>
        </b-row>
        <!-- REQUEST TRAVEL MODAL -->
        <b-modal :id="requestTravelModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Request Travel"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <RequestTravel
                    :fromTravel="fromTravel"
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @done="completeRegistration" />
        </b-modal>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import {RegistrationRequest, RegistrationRequestType} from "@/model/registration";
    import RequestTravel from "@/views/private/tracks/RequestTravel.vue";
    import _ from 'underscore';
    import {errorModalOptions} from "../../../util/formatters";

    @Component({
        components: {
            RequestTravel
        }
    })

    export default class ConfirmRegistration extends Vue {
        @Prop({type: RegistrationRequest, default: null}) registrationRequest;
        @Prop({type: [String, Object]}) requestType;
        @Prop({type: Boolean, default: false}) fromTravel;

        mounted() {
            // console.log(this.registrationRequest);
        }

        get confirmationMessage() {
            if (_.isEmpty(this.registrationRequest)) {
                return 'Yikes, something went wrong!';
            }
            const template = 'Are you sure you want to register %s for %s?';
            const message = sprintf(template, this.registrationRequest.user.fullname,
                this.registrationRequest.session.label);
            return message;
        }

        get requestTravelModalId() {
            if (_.isEmpty(this.registrationRequest)) {
                return 'request-travel-modal-invalid';
            }
            else {
                return sprintf('request-travel-modal-%d-%d', this.registrationRequest.user.id,
                    this.registrationRequest.session.workshopInstanceId);
            }
        }

        cancel() {
            this.registrationRequest.reset();
            this.$emit('canceled');
        }

        //Called upon receipt of 'done' from RequestTravel (no need to hide request travel modal)
        completeRegistration(details) {
            this.$emit('registered', details);
        }

        async register() {
            try {
                if (_.isEqual(this.requestType, RegistrationRequestType.INSTRUCTOR_SESSION_REGISTRATION)) {
                    await this.$store.dispatch('instructor/processRegistration', this.registrationRequest, {root: true});
                    // console.log(this.registrationRequest);
                    this.$bvModal.show(this.requestTravelModalId);
                }
                else if (_.isEqual(this.requestType, RegistrationRequestType.SESSION_REGISTRATION)) {
                    await this.$store.dispatch('registration/processRegistration', this.registrationRequest, {root: true});
                    // console.log(this.registrationRequest);
                    this.$bvModal.show(this.requestTravelModalId);
                }
                else {
                    alert('Cannot complete registration for unknown RegistrationRequestType ' + this.requestType);
                    this.cancel();
                }
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                this.cancel();
            }
        }
    }
</script>

<style scoped>
</style>

