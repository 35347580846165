
let messageVNode = null;


export default function (Vue) {

    if (!!messageVNode) {
        return messageVNode;
    }

    const h = Vue.$createElement;

    messageVNode = h('div', {}, [
        h('p', {}, ['You cannot register a participant unless they are vaccinated for COVID-19.']),
        h('p', {}, [`UBC Members: Must contact their Local Training Center to verify their vaccine card
                              and add the COVID-19 Vaccine Verified Qual/Cert in the TRAIN system.`]),
        h('p', {}, ['Non-UBC Members: Please contact CITF Administration.'])
    ]);

    return messageVNode;
}
