<template>
    <div>
        <b-card>
            <b-row>
                <b-col>
                    <strong>{{sessionName}}</strong>
                    <br /><br />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Dates:</strong> {{sessionDates}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Tech Coordinator:</strong> {{sessionCoordinator}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Available Seats:</strong> {{sessionAvailableSeats}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Description:</strong>
                    <br />
                    <span
                            v-html="sessionDescription" />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import formatters from '@/util/formatters';
    import {InstructorSession, Session, WorkshopInstance, NULL_SESSION} from "@/model/session";

    @Component({})

    export default class SessionDetails extends Vue {
        @Prop({type: [Session, InstructorSession, WorkshopInstance], default: NULL_SESSION}) session;

        mounted() {
            // console.log(this.session);
        }

        get isValidSession() {
            return !_.isEmpty(this.session) && !_.isUndefined(this.session) && !_.isEqual(this.session, NULL_SESSION);
        }

        get sessionName() {
            return this.isValidSession ? this.session.name : 'Invalid Session';
        }

        get sessionDescription() {
            return this.isValidSession ? this.session.description : '';
        }

        get sessionDates() {
            if (this.isValidSession && !_.isUndefined(this.session.dates[0]) && !_.isUndefined(this.session.dates[1])) {
                return sprintf('%s to %s',
                    formatters.date(this.session.dates[0], 'M/d/yyyy'),
                    formatters.date(this.session.dates[1], 'M/d/yyyy'));
            }
            else {
                return '';
            }
        }

        get sessionCoordinator() {
            return this.isValidSession ? this.session.techCoordinator : '';
        }

        get sessionAvailableSeats() {
            if (this.isValidSession && !_.isNaN(this.session.availableSeats)) {
                return sprintf('%d of %d', this.session.availableSeats, this.session.maxSeats);
            }
            else {
                return '';
            }
        }
    }
</script>

<style scoped>

</style>