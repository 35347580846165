<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <b-form-group
                            label-for="ubc-id-search"
                            label="UBC ID:">
                        <b-input-group>
                            <b-input id="ubc-id-search" size="sm"
                                     v-model="ubcIdFilter" />
                            <b-input-group-addon>
                                <b-button size="sm"
                                          @click="ubcIdFilter = ''">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </b-input-group-addon>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            label-for="name-search"
                            label="Name:">
                        <b-input-group>
                            <b-input id="name-search" size="sm"
                                     v-model="nameFilter" />
                            <b-input-group-addon>
                                <b-button size="sm"
                                          @click="nameFilter = ''">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </b-input-group-addon>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            label-for="status-filter"
                            label="Status:">
                        <b-input-group>
                            <b-select
                                    :options="statusFilters"
                                    id="status-filter" size="sm"
                                    v-model="statusFilter" />
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <b-table small striped hover bordered stacked="sm" head-variant="dark"
                                 :items="registeredUsers"
                                 :fields="fields" >
                            <template v-slot:cell(ubcId)="row">{{row.item.user.ubcId}}</template>
                            <template v-slot:cell(fullname)="row">{{row.item.user.fullname}}</template>
                            <template v-slot:cell(register)="row">
                                <template v-if="allowRegistrationChanges && allowRegistrationFor(row.item.user)">
                                    <b-dropdown
                                            :variant="statusVariant(row.item.status)"
                                            :text="row.item.status"
                                            size="sm">
                                        <template v-if="isRegistered(row.item.status) && !!row.item.hasRegistrationId">
                                            <!--It takes too long for the store calls to return for UI rendering-->
                                            <!--:disabled="!userIsCancelable(row.item.user) || !isCancellable(row.item)"-->
                                            <b-dropdown-item
                                                    href="#"
                                                    @click="cancel(row.item)">Cancel registration</b-dropdown-item>
                                        </template>
                                        <template v-else-if="isScheduled(row.item.status)">
                                            <!--It takes too long for the store calls to return for UI rendering-->
                                            <!--:disabled="!userIsRegistrable(row.item.user) || !isRegistrable(row.item)"-->
                                            <b-dropdown-item
                                                    href="#"
                                                    @click="register(row.item)">Register</b-dropdown-item>
                                        </template>
                                        <template v-else>
                                            <b-dropdown-item>No actions available</b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </template>
                                <template v-else>
                                    <b-button
                                            :variant="statusVariant(row.item.status)"
                                            size="sm">{{row.item.status}}</b-button>
                                </template>
                            </template>
                        </b-table>
                        <template v-if="!registeredUsers.length">
                            No registered or scheduled users found for this session
                        </template>
                    </div>
                </b-col>
            </b-row>
            <!-- CONFIRM REGISTRATION MODAL -->
            <b-modal :id="registrationModalId"
                     size="xl"
                     scrollable
                     noCloseOnBackdrop
                     noCloseOnEsc
                     hideHeaderClose
                     hideFooter
                     title="Confirm Registration"
                     header-bg-variant="dark"
                     header-text-variant="white">
                <ConfirmRegistration
                        :registrationRequest="registrationRequest"
                        :requestType="requestType"
                        @registered="registered"
                        @canceled="canceled" />
            </b-modal>
        </b-container>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import store from '@/store/store';
    import {Session, NULL_SESSION} from "@/model/session";
    import {Status, RegistrationRequest, RegistrationRequestType, NEW_SESSION_REGISTRATION} from "@/model/registration";
    import {SecurityLevel} from "@/model/security_level";
    import User from "@/model/user";
    import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
    import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

    @Component({
        components: {
            ConfirmRegistration
        },
        asyncComputed: {
            registrableUsers: {
                async get() {
                    try {
                        await store.dispatch('registration/loadRegistrableUserIds');
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const users = store.getters['registration/registrableUserIds'];
                    return users;
                }
            },
            cancelableUsers: {
                async get() {
                    try {
                        await store.dispatch('registration/loadCancelableUserIds');
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const users = store.getters['registration/cancelableUserIds'];
                    return users;
                }
            },
            registeredUsers: {
                async get() {
                    if (Session.isValidSession(this.session)) {
                        try {
                            await this.$store.dispatch('registration/loadSessionRoster', this.session);
                        }
                        catch (error) {
                            this.$bvToast.toast(error.message, errorToastOptions);
                        }
                        const users = this.$store.getters['registration/sessionRoster'](this.session);
                        const filteredUsers = _.chain(users)
                            .filter(user => {
                                if (!_.isEmpty(this.nameFilter)) {
                                    return user.fullname.toUpperCase().includes(this.nameFilter.toUpperCase());
                                }
                                else {
                                    return user;
                                }
                            })
                            .filter(user => {
                                if (!_.isEmpty(this.ubcIdFilter)) {
                                    return !_.isEmpty(user.ubcId) &&
                                        user.ubcId.toUpperCase().includes(this.ubcIdFilter.toUpperCase());
                                }
                                else {
                                    return user;
                                }
                            })
                            .filter(user => {
                                if (!_.isEmpty(this.statusFilter)) {
                                    switch (this.statusFilter) {
                                        case Status.SCHEDULED:
                                        case Status.REGISTERED:
                                            return _.isEqual(user.rosterStatus, this.statusFilter);
                                        case 'ALL':
                                        default:
                                            return true;
                                    }
                                }
                            })
                            .sortBy(user => {
                                return user.fullname;
                            })
                            .map(user => {
                                const registration = this.isRegistered(user.rosterStatus) ?
                                    user.getRegistrationForSession(this.session) : NEW_SESSION_REGISTRATION;
                                const scheduleItem = new RegistrationRequest(user, this.session, registration);
                                return scheduleItem;
                            })
                            .value();
                        return filteredUsers;
                    }
                },
                default: [],
                watch: ['ubcIdFilter', 'nameFilter', 'statusFilter', 'session']
            }
        },
        methods: {
            refreshUsers() {
                this.$asyncComputed.registeredUsers.update();
                this.$asyncComputed.registrableUsers.update();
                this.$asyncComputed.cancelableUsers.update();
            }
        }
    })

    export default class SessionRoster extends Vue {
        @Prop({type: Boolean, default: true}) allowRegistrationChanges;
        @Prop({type: Session, default: NULL_SESSION}) session;
        ubcIdFilter = '';
        nameFilter = '';
        statusFilter = this.defaultStatusFilter;
        registrationRequest = null;

        registered() {
            this.refreshUsers();
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
        }

        canceled() {
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
        }

        get requestType() {
            return RegistrationRequestType.SESSION_REGISTRATION;
        }

        get registrationModalId() {
            return sprintf('confirm-registration-modal-%d', this.session.sessionId);
        }

        get defaultStatusFilter() {
            return 'ALL';
        }

        get statusFilters() {
            return [this.defaultStatusFilter, Status.SCHEDULED, Status.REGISTERED];
        }

        statusVariant(status) {
            return Status.variant(status);
        }

        isRegistered(status) {
            return _.isEqual(status, Status.REGISTERED);
        }

        isScheduled(status) {
            return _.isEqual(status, Status.SCHEDULED);
        }

        userIsRegistrable(user) {
            if (!User.isValidUser(user)) {
                return false;
            }
            const registrable = this.registrableUsers.includes(user.id);
            return registrable;
        }

        userIsCancelable(user) {
            if (!User.isValidUser(user)) {
                return false;
            }
            const cancelable = this.cancelableUsers.includes(user.id);
            return cancelable;
        }

        isCancellable(scheduleItem) {
            if (!!scheduleItem.registration.id && this.isRegistered(scheduleItem.status)) {
                return scheduleItem.registration.cancelable;
            }
            //Non-registered statuses can't cancel
            else {
                return false;
            }
        }

        async isRegistrable(scheduleItem) {
            if (this.isScheduled(scheduleItem.status)) {
                try {
                    await this.$store.dispatch('registration/loadRegistrableSessionsByUser', scheduleItem.user);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const registrableSessionsForUser = this.$store.getters['registration/registrableSessionsByUser'](scheduleItem.user);
                const registrable = _.find(registrableSessionsForUser, session => {
                    return session.sessionId === scheduleItem.session.sessionId;
                });
                return _.isUndefined(registrable) ? false : true;
            }
            //Non-scheduled statuses can't register
            else {
                return false;
            }
        }

        allowRegistrationFor(user) {
            const currentUser = store.getters['userSession/getUser'];
            switch (currentUser.securityLevel) {
                case SecurityLevel.ADMINISTRATOR:
                    return this.session.allowRegistrationChanges(currentUser.securityLevel);
                case SecurityLevel.MENTOR:
                    return this.session.allowRegistrationChanges(currentUser.securityLevel) &&
                        currentUser.canRegisterFor(user);
                default:
                    return false;
            }
        }

        async cancel(scheduleItem) {
            if (!this.userIsCancelable(scheduleItem.user) || !this.isCancellable(scheduleItem)) {
                await this.$bvModal.msgBoxOk('Sorry, user is not eligible to cancel registration for this session.');
            }
            else {
                const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                    'Are you sure you wish to cancel the registration for user %s for session %s? ' +
                    'Any related travel requests will be canceled as well.',
                    scheduleItem.user.fullname, scheduleItem.session.label), {
                    title: 'Confirm Registration Cancellation',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
                if (!cancelCheck) {
                    return;
                }
                scheduleItem.registration.canceled = true;
                try {
                    await this.$store.dispatch('registration/cancelRegistration', scheduleItem, {root: true});
                    this.refreshUsers();
                }
                catch (error) {
                    await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                    scheduleItem.registration.canceled = false;
                }
            }
        }

        async register(scheduleItem) {
            if (!this.userIsRegistrable || !(await this.isRegistrable(scheduleItem))) {
                await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
            }
            else {
                //Check for override
                if (scheduleItem.needsOverride) {
                    const activeUser = store.getters['userSession/getUser'];
                    if (activeUser.isAnAdministrator()) {
                        const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                            'register for a session that requires vaccine verification has not been verified. Would you ' +
                            'like to override the vaccine requirement and register this participant anyway?', {
                            title: 'Vaccine Override Needed',
                            noCloseOnBackdrop: true,
                            noCloseOnEsc: true,
                            headerBgVariant: 'dark',
                            headerTextVariant: 'white'
                        });
                        if (check) {
                            //Set override flag
                            scheduleItem.registration.vaccineOverride = true;
                        } else {
                            return;
                        }
                    } else {
                        //A non-admin cannot override
                        return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                            size: 'md',
                            buttonSize: 'sm'
                        });
                    }
                }
                this.registrationRequest = scheduleItem;
                this.$bvModal.show(this.registrationModalId);
            }
        }

        mounted() {
            // console.log(this.session);
        }

        get fields() {
            const baseFields = [{
                key: 'ubcId',
                label: 'UBC ID',
                sortable: true
            }, {
                key: 'fullname',
                label: 'Name',
                sortable: true
            }, {
                key: 'register',
                label: 'Status',
                sortable: false
            }];
            return baseFields;
        }
    }
</script>

<style scoped>
    b-dropdown-item.options {
    }
</style>