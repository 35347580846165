<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <b-form-group
                            label-for="ubc-id-search"
                            label="UBC ID:">
                        <b-input-group>
                            <b-input id="ubc-id-search" size="sm"
                                     v-model="ubcIdFilter" />
                            <b-input-group-addon>
                                <b-button size="sm"
                                          @click="ubcIdFilter = ''">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </b-input-group-addon>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            label-for="name-search"
                            label="Name:">
                        <b-input-group>
                            <b-input id="name-search" size="sm"
                                     v-model="nameFilter" />
                            <b-input-group-addon>
                                <b-button size="sm"
                                          @click="nameFilter = ''">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </b-input-group-addon>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            label-for="track-filter"
                            label="Part of Track?">
                        <b-input-group>
                            <b-select
                                    :options="trackFilters"
                                    id="track-filter" size="sm"
                                    v-model="trackFilter" />
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <b-table small striped hover bordered stacked="sm" head-variant="dark"
                                 :items="registrableUsers"
                                 :fields="fields" >
                            <template v-slot:cell(ubcId)="row">{{row.item.user.ubcId}}</template>
                            <template v-slot:cell(fullname)="row">{{row.item.user.fullname}}</template>
                            <template v-slot:cell(trackassociation)="row">{{isPartOfTrack(row.item.user) | upper}}</template>
                            <template v-slot:cell(register)="row">
                                <template v-if="allowRegistrationChanges && allowRegistrationFor(row.item.user)">
                                    <b-dropdown
                                            :variant="statusVariant(row.item.status)"
                                            :text="statusLabel(row.item)"
                                            size="sm">
                                        <!--It takes too long for the store calls to return for UI rendering-->
                                        <!--:disabled="!userIsRegistrable(row.item.user) || !isRegistrable(row.item)"-->
                                        <template v-if="isScheduled(row.item.status)">
                                            <b-dropdown-item
                                                    href="#"
                                                    @click="register(row.item)">Register</b-dropdown-item>
                                        </template>
                                        <template v-else>
                                            <b-dropdown-item>No actions available</b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </template>
                                <template v-else>
                                    <b-button
                                            disabled
                                            :variant="statusVariant(row.item.status)"
                                            :text="row.item.status"
                                            size="sm" />
                                </template>
                            </template>
                        </b-table>
                        <template v-if="!registrableUsers.length">
                            No registrable users found for this session
                        </template>
                    </div>
                </b-col>
            </b-row>
            <!-- CONFIRM REGISTRATION MODAL -->
            <b-modal :id="registrationModalId"
                     size="xl"
                     scrollable
                     noCloseOnBackdrop
                     noCloseOnEsc
                     hideHeaderClose
                     hideFooter
                     title="Confirm Registration"
                     header-bg-variant="dark"
                     header-text-variant="white">
                <ConfirmRegistration
                        :registrationRequest="registrationRequest"
                        :requestType="requestType"
                        @registered="registered"
                        @canceled="canceled" />
            </b-modal>
        </b-container>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import store from '@/store/store';
    import {Session, NULL_SESSION} from "@/model/session";
    import {Status, RegistrationRequest, NEW_SESSION_REGISTRATION, RegistrationRequestType} from "@/model/registration";
    import {SecurityLevel} from "@/model/security_level";
    import User from "@/model/user";
    import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
    import {errorToastOptions} from "../../../util/formatters";
    import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

    @Component({
        components: {
            ConfirmRegistration
        },
        asyncComputed: {
            registrableUserIds: {
                async get() {
                    try {
                        await store.dispatch('registration/loadRegistrableUserIds');
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const userIds = store.getters['registration/registrableUserIds'];
                    return userIds;
                }
            },
            registrableUsers: {
                async get() {
                    if (Session.isValidSession(this.session)) {
                        try {
                            await this.$store.dispatch('registration/loadRegistrableUsersBySession', this.session);
                        }
                        catch (error) {
                            this.$bvToast.toast(error.message, errorToastOptions);
                        }
                        const users = this.$store.getters['registration/registrableUsersBySession'](this.session);
                        const filteredUsers = _.chain(users)
                            .filter(user => {
                                if (!_.isEmpty(this.nameFilter)) {
                                    return user.fullname.toUpperCase().includes(this.nameFilter.toUpperCase());
                                }
                                else {
                                    return user;
                                }
                            })
                            .filter(user => {
                                if (!_.isEmpty(this.ubcIdFilter)) {
                                    return !_.isEmpty(user.ubcId) &&
                                        user.ubcId.toUpperCase().includes(this.ubcIdFilter.toUpperCase());
                                }
                                else {
                                    return user;
                                }
                            })
                            .filter(user => {
                                if (!_.isEmpty(this.trackFilter)) {
                                    switch (this.trackFilter) {
                                        case 'TRUE':
                                            return this.isPartOfTrack(user) === true;
                                        case 'FALSE':
                                            return this.isPartOfTrack(user) === false;
                                        case 'ALL':
                                        default:
                                            return true;
                                    }
                                }
                            })
                            .sortBy(user => {
                                return user.fullname;
                            })
                            .map(user => {
                                // const program = Program.create({name: sprintf('PROGRAM_%d', this.session.idx)}, this.session.mentorSession);
                                const scheduleItem = new RegistrationRequest(user, this.session, NEW_SESSION_REGISTRATION);
                                return scheduleItem;
                            })
                            .value();
                        return filteredUsers;
                    }
                },
                default: [],
                watch: ['ubcIdFilter', 'nameFilter', 'trackFilter', 'session']
            },
        },
        methods: {
            refreshUsers() {
                this.$asyncComputed.registrableUsers.update();
                this.$asyncComputed.registrableUserIds.update();
            }
        },
        filters: {
            upper: (word) => {
                return String(word).toUpperCase();
            }
        }
    })

    export default class SessionRoster extends Vue {
        @Prop({type: Boolean, default: true}) allowRegistrationChanges;
        @Prop({type: Session, default: NULL_SESSION}) session;
        ubcIdFilter = '';
        nameFilter = '';
        trackFilter = this.defaultTrackFilter;
        registrationRequest = null;

        registered() {
            this.refreshUsers();
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
        }

        canceled() {
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
        }

        get requestType() {
            return RegistrationRequestType.SESSION_REGISTRATION;
        }

        get registrationModalId() {
            return sprintf('confirm-registration-modal-%d', this.session.sessionId);
        }

        get defaultTrackFilter() {
            return 'ALL';
        }

        get trackFilters() {
            return [this.defaultTrackFilter, 'TRUE', 'FALSE'];
        }

        statusLabel(scheduleItem) {
            const status = scheduleItem.status;
            if (this.isScheduled(status)) {
                //Check for track status before calling this scheduled!
                return this.isPartOfTrack(scheduleItem.user) ? status : sprintf('NOT %s', status);
            }
            else {
                return status;
            }
        }

        statusVariant(status) {
            return Status.variant(status);
        }

        isRegistered(status) {
            return _.isEqual(status, Status.REGISTERED);
        }

        isScheduled(status) {
            return _.isEqual(status, Status.SCHEDULED);
        }

        isPartOfTrack(user) {
            //Don't check without a valid user and session
            if (!Session.isValidSession(this.session) || !User.isValidUser(user)) {
                return false;
            }
            //Because of mentor's registration dependency on trainees, go ahead and call it "part of track"
            if (_.isEqual(user.securityLevel, SecurityLevel.MENTOR)) {
                return true;
            }
            //Check track for trainee
            if (_.isEqual(user.securityLevel, SecurityLevel.TRAINEE) && !_.isNull(user.trackId)) {
                return user.trackId === this.session.trackId;
            }
            //Not enough valid info
            else {
                return false;
            }
        }

        userIsRegistrable(user) {
            if (!User.isValidUser(user)) {
                return false;
            }
            const registrable = this.registrableUserIds.includes(user.id);
            return registrable;
        }

        async isRegistrable(scheduleItem) {
            if (this.isScheduled(scheduleItem.status)) {
                try {
                    await this.$store.dispatch('registration/loadRegistrableSessionsByUser', scheduleItem.user);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const registrableSessionsForUser = this.$store.getters['registration/registrableSessionsByUser'](scheduleItem.user);
                const registrable = _.find(registrableSessionsForUser, session => {
                    return session.sessionId === scheduleItem.session.sessionId;
                });
                return _.isUndefined(registrable) ? false : true;
            }
            //Non-scheduled statuses can't register
            else {
                return false;
            }
        }

        allowRegistrationFor(user) {
            const currentUser = store.getters['userSession/getUser'];
            switch (currentUser.securityLevel) {
                case SecurityLevel.ADMINISTRATOR:
                    return this.session.allowRegistrationChanges(currentUser.securityLevel);
                case SecurityLevel.MENTOR:
                    return this.session.allowRegistrationChanges(currentUser.securityLevel) && currentUser.canRegisterFor(user);
                default:
                    return false;
            }
        }

        async register(scheduleItem) {
            if (!this.userIsRegistrable || !(await this.isRegistrable(scheduleItem))) {
                await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
            }
            else {
                //Check for override
                if (scheduleItem.needsOverride) {
                    const activeUser = store.getters['userSession/getUser'];
                    if (activeUser.isAnAdministrator()) {
                        const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                            'register for a session that requires vaccine verification has not been verified. Would you ' +
                            'like to override the vaccine requirement and register this participant anyway?', {
                            title: 'Vaccine Override Needed',
                            noCloseOnBackdrop: true,
                            noCloseOnEsc: true,
                            headerBgVariant: 'dark',
                            headerTextVariant: 'white'
                        });
                        if (check) {
                            //Set override flag
                            scheduleItem.registration.vaccineOverride = true;
                        } else {
                            return;
                        }
                    } else {
                        //A non-admin cannot override
                        return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                            size: 'md',
                            buttonSize: 'sm'
                        });
                    }
                }
                this.registrationRequest = scheduleItem;
                this.$bvModal.show(this.registrationModalId);
            }
        }

        mounted() {
            // console.log(this.session);
        }

        statusVariant(status) {
            return Status.variant(status);
        }

        get fields() {
            return [{
                key: 'ubcId',
                label: 'UBC ID',
                sortable: true
            }, {
                key: 'fullname',
                label: 'Name',
                sortable: true
            }, {
                key: 'trackassociation',
                label: 'Part of Track?',
                sortable: true
            }, {
                key: 'register',
                label: 'Register',
                sortable: false
            }];
        }
    }
</script>

<style scoped>
    b-dropdown-item.options {
    }
</style>