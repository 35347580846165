<template>
    <div>
        <b-row>
            <b-col>
                <p>{{travelMessage}}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="float-right">
                    <b-button
                            variant="primary"
                            @click="requestTravel(true, true)">Yes</b-button>
                    <b-button
                            variant="secondary"
                            @click="requestTravel(true, false)">Not right now</b-button>
                    <b-button
                            variant="warning"
                            @click="requestTravel(false)">No</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import {RegistrationRequest} from "@/model/registration";
    import _ from 'underscore';
    import {errorModalOptions} from "../../../util/formatters";

    @Component({})

    export default class RequestTravel extends Vue {
        @Prop({type: RegistrationRequest, default: null}) registrationRequest;
        @Prop({type: [String, Object]}) requestType;
        @Prop({type: Boolean, default: false}) fromTravel;

        mounted() {
            // console.log(this.registrationRequest);
        }

        get travelMessage() {
            if (_.isEmpty(this.registrationRequest)) {
                return 'Yikes, something went wrong!';
            }
            const template = 'Would you like to request travel for %s to attend %s?';
            const message = sprintf(template, this.registrationRequest.user.fullname, this.registrationRequest.session.label);
            return message;
        }

        async requestTravel(travelNeeded, doItNow = false) {
            //Update registration and submit
            this.registrationRequest.registration.declinedTravel = !travelNeeded;
            try {
                await this.$store.dispatch('registration/declinedTravel', {
                    registrationId: this.registrationRequest.registration.id,
                    declinedTravel: this.registrationRequest.registration.declinedTravel
                }, {root: true});
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                return;
            }
            //Route to travel module as requested
            // console.log(this.registrationRequest);
            if (travelNeeded && doItNow && !this.fromTravel) {
                //From outside of the Travel module, use router to redirect
                return this.$router.push({
                    name: 'selectedTravel',
                    params: {
                        attendeeId: this.registrationRequest.user.attendeeId,
                        registrationId: this.registrationRequest.registration.id
                    }
                });
            }
            //Notify parent
            this.$emit('done', {
                travelNeeded: travelNeeded,
                doItNow: doItNow,
                attendeeId: this.registrationRequest.user.attendeeId,
                registrationId: this.registrationRequest.registration.id
            });
        }
    }
</script>

<style scoped>
</style>

